import { Injectable } from '@angular/core';
import { DeviceDetectorService, OS } from 'ngx-device-detector';
import { KeyboardShortcutsHelpComponent, ShortcutInput } from 'ng-keyboard-shortcuts';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KeyboardShortcutService {

  private _keyboardHelp: KeyboardShortcutsHelpComponent;
  private _keyboardEvents = new Subject<any>();
  private _dynamicShortcuts = new Subject<ShortcutInput>();
  keyboardEvents$ = this._keyboardEvents.asObservable();
  dynamicShortcuts$ = this._dynamicShortcuts.asObservable();

  constructor(private readonly deviceDetectorService: DeviceDetectorService) {}

  setKeyboardHelp(keyboardHelp: KeyboardShortcutsHelpComponent) {
    this._keyboardHelp = keyboardHelp;
  }

  osDependentShortcut(keys: string): string {
    if (this.deviceDetectorService.os === OS.MAC || this.deviceDetectorService.os === OS.IOS) {
      return keys.replace('ctrl', 'cmd');
    }
    return keys.replace('cmd', 'ctrl');
  }

  showKeyboardHelp() {
    if (this._keyboardHelp) {
      this._keyboardHelp.reveal();
    }
  }

  forwardKeyboardEvent(event: any) {
    this._keyboardEvents.next(event);
  }

  forwardDynamicShortcut(dynamicShortcut: ShortcutInput) {
    this._dynamicShortcuts.next(dynamicShortcut);
  }
}
